
import { Options } from 'vue-class-component'
import { BaseStepComponent, baseStepComponentProps } from '@/components/CurtainConfigurator/base-step-component'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import SelectBox from '@/components/Form/Input/SelectBox.vue'
import CarouselImageSelector from '@/components/Form/Input/CarouselImageSelector.vue'
import { Inject } from 'inversify-props'
import { WrinkleType } from '@/models/wrinkle-type'
import { SelectBoxItem } from '@/interfaces/select-box-item'
import { AvailablePreviewImageService } from '@/services/available-preview-image.service'
import { AvailablePreviewImageFilter } from '@/enums/available-preview-image-filter'
import { WrinkleTextileRatio } from '@/models/wrinkle-textile-ratio'
import { WrinkleColor } from '@/models/wrinkle-color'
import { WrinkleNumberOfFolds } from '@/models/wrinkle-number-of-folds'
import { WrinklePatternRepetitionDistance } from '@/models/wrinkle-pattern-repetition-distance'
import { WrinkleWidth } from '@/models/wrinkle-width'
import TilesImageSelector from '@/components/Form/Input/TilesImageSelector.vue'
import { TilesImageItem } from '@/interfaces/tiles-image-item'
import { Wrinkle } from '@/models/wrinkle'
import { PreviewImage } from '@/models/preview-image'

type WrinkleTypeSelectBoxItem = SelectBoxItem<WrinkleType>
type WrinkleNumberOfFoldsSelectBoxItem = SelectBoxItem<WrinkleNumberOfFolds>
type WrinkleColorSelectBoxItem = SelectBoxItem<WrinkleColor>
type WrinkleTextileRatioSelectBoxItem = SelectBoxItem<WrinkleTextileRatio>
type WrinkleWidthInMillimetersSelectBoxItem = SelectBoxItem<WrinkleWidth>
type WrinklePatternRepetitionDistanceInMillimetersSelectBoxItem = SelectBoxItem<WrinklePatternRepetitionDistance>
type WrinklesAsTilesImageItem = TilesImageItem<Wrinkle>

@Options({
  components: {
    TilesImageSelector,
    SelectBox,
    CarouselImageSelector
  },
  props: baseStepComponentProps,
  watch: {
    'configuratorSnapshot.selectedWrinkleType' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinkleNumberOfFolds' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinkleColor' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinkleTextileRatio' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinkleWidthInMillimeters' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinklePatternRepetitionDistanceInMillimeters' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    }
  }
})
export default class WrinkleConfiguration extends BaseStepComponent {
  @Inject() private readonly availablePreviewImageService!: AvailablePreviewImageService
  public readonly configuratorSnapshot!: ConfiguratorSnapshot
  public wrinkleTypesAsSelectBoxItems: WrinkleTypeSelectBoxItem[] = []
  public wrinkleNumberOfFoldsAsSelectBoxItems: WrinkleNumberOfFoldsSelectBoxItem[] = []
  public wrinkleColorsAsSelectBoxItems: WrinkleColorSelectBoxItem[] = []
  public wrinkleTextileRatiosAsSelectBoxItems: WrinkleTextileRatioSelectBoxItem[] = []
  public wrinkleWidthInMillimetersAsSelectBoxItems: WrinkleWidthInMillimetersSelectBoxItem[] = []
  public wrinklePatternRepetitionDistanceInMillimetersAsSelectBoxItems: WrinklePatternRepetitionDistanceInMillimetersSelectBoxItem[] = []
  public wrinklesAsTilesImageItems: WrinklesAsTilesImageItem[] = []

  public created (): void {
    this.setWrinklesAsTilesImageItems()
    this.setFiltersValues()
  }

  private setWrinklesAsTilesImageItems (): void {
    this.getAvailableDistinctWrinkles()
      .then(wrinkles => {
        this.wrinklesAsTilesImageItems = wrinkles
          .sort((wrinkleA, wrinkleB) => wrinkleA.sorting - wrinkleB.sorting)
          .map(wrinkle => ({
            value: wrinkle,
            title: wrinkle.title,
            imageUrl: wrinkle.previewImage?.url,
            hoverImageUrl: wrinkle.zoomImage?.url
          }))
      })
  }

  private getAvailableDistinctWrinkles (): Promise<Wrinkle[]> {
    if (!this.configuratorSnapshot.selectedRoom) {
      return Promise.resolve([])
    }

    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, (AvailablePreviewImageFilter.Step2 ^ AvailablePreviewImageFilter.Wrinkle))
      .then(previewImages => {
        const wrinkles = previewImages
          .filter(previewImage => !!previewImage.wrinkle)
          .map(previewImage => previewImage.wrinkle)

        return [...new Map(wrinkles.map(wrinkle => [wrinkle.uid, wrinkle])).values()]
      })
  }

  private setFiltersValues (): void {
    this.setWrinkleTypesAsSelectBoxItems()
    this.setWrinkleNumberOfFoldsAsSelectBoxItems()
    this.setWrinkleColorsAsSelectBoxItems()
    this.setWrinkleTextileRatiosAsSelectBoxItems()
    this.setWrinkleWidthsAsSelectBoxItems()
    this.setWrinklePatternRepetitionDistancesAsSelectBoxItems()
  }

  private setWrinkleTypesAsSelectBoxItems (): void {
    Promise.all([
      this.getAllWrinkleTypes(),
      this.getAvailableWrinkleTypesIds()]
    ).then(([allWrinkleTypes, availableWrinkleTypesIds]) => {
      this.wrinkleTypesAsSelectBoxItems = allWrinkleTypes.map(wrinkleType => {
        return {
          value: wrinkleType,
          title: wrinkleType.title,
          disabled: Boolean(wrinkleType.id && !availableWrinkleTypesIds.includes(wrinkleType.id)),
          sorting: wrinkleType.sorting
        }
      })
    })
  }

  private getAllWrinkleTypes (): Promise<WrinkleType[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.None)
      .then(previewImages => this.getWrinkleTypesFromPreviewImagesCollection(previewImages))
  }

  private getAvailableWrinkleTypesIds (): Promise<string[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step2 ^ AvailablePreviewImageFilter.WrinkleType ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => this.getWrinkleTypesFromPreviewImagesCollection(previewImages))
      .then(wrinkleTypes => wrinkleTypes.map(wrinkleType => wrinkleType.id).filter(Boolean) as string[])
  }

  private getWrinkleTypesFromPreviewImagesCollection (previewImages: PreviewImage[]): WrinkleType[] {
    return previewImages.map(previewImage => (previewImage.wrinkle?.type)).filter(Boolean)
  }

  private setWrinkleNumberOfFoldsAsSelectBoxItems (): void {
    Promise.all([
      this.getAllWrinkleNumberOfFolds(),
      this.getAvailableWrinkleNumberOfFolds()]
    ).then(([allWrinkleNumberOfFolds, availableWrinkleNumberOfFolds]) => {
      this.wrinkleNumberOfFoldsAsSelectBoxItems = allWrinkleNumberOfFolds.map(wrinkleNumberOfFolds => {
        return {
          value: new WrinkleNumberOfFolds(wrinkleNumberOfFolds),
          title: wrinkleNumberOfFolds.toString(),
          disabled: !availableWrinkleNumberOfFolds.includes(wrinkleNumberOfFolds),
          sorting: wrinkleNumberOfFolds
        }
      })
    })
  }

  private getAllWrinkleNumberOfFolds (): Promise<number[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.None)
      .then(previewImages => this.getWrinkleNumberOfFoldsFromPreviewImagesCollection(previewImages))
  }

  private getAvailableWrinkleNumberOfFolds (): Promise<number[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step2 ^ AvailablePreviewImageFilter.WrinkleNumberOfFolds ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => this.getWrinkleNumberOfFoldsFromPreviewImagesCollection(previewImages))
  }

  private getWrinkleNumberOfFoldsFromPreviewImagesCollection (previewImages: PreviewImage[]): number[] {
    return previewImages.map(previewImage => (previewImage.wrinkle?.numberOfFolds)).filter(numberOfFolds => typeof numberOfFolds === 'number')
  }

  private setWrinkleColorsAsSelectBoxItems (): void {
    Promise.all([
      this.getAllWrinkleColors(),
      this.getAvailableWrinkleColorsIds()]
    ).then(([allWrinkleColors, availableWrinkleColorsIds]) => {
      this.wrinkleColorsAsSelectBoxItems = allWrinkleColors.map(wrinkleColor => {
        return {
          value: wrinkleColor,
          title: wrinkleColor.title,
          disabled: Boolean(wrinkleColor.id && !availableWrinkleColorsIds.includes(wrinkleColor.id)),
          sorting: wrinkleColor.sorting
        }
      })
    })
  }

  private getAllWrinkleColors (): Promise<WrinkleType[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.None)
      .then(previewImages => this.getWrinkleColorsFromPreviewImagesCollection(previewImages))
  }

  private getAvailableWrinkleColorsIds (): Promise<string[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step2 ^ AvailablePreviewImageFilter.WrinkleColor ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => this.getWrinkleColorsFromPreviewImagesCollection(previewImages))
      .then(wrinkleColors => wrinkleColors.map(wrinkleColor => wrinkleColor.id).filter(Boolean) as string[])
  }

  private getWrinkleColorsFromPreviewImagesCollection (previewImages: PreviewImage[]): WrinkleType[] {
    return previewImages.map(previewImage => (previewImage.wrinkle?.color)).filter(Boolean)
  }

  private setWrinkleTextileRatiosAsSelectBoxItems (): void {
    Promise.all([
      this.getAllWrinkleTextileRatios(),
      this.getAvailableWrinkleTextileRatiosIds()]
    ).then(([allWrinkleTextileRatios, availableWrinkleTextileRatiosIds]) => {
      this.wrinkleTextileRatiosAsSelectBoxItems = allWrinkleTextileRatios.map(wrinkleTextileRatio => {
        return {
          value: wrinkleTextileRatio,
          title: wrinkleTextileRatio.title,
          disabled: Boolean(wrinkleTextileRatio.id && !availableWrinkleTextileRatiosIds.includes(wrinkleTextileRatio.id)),
          sorting: wrinkleTextileRatio.sorting
        }
      })
    })
  }

  private getAllWrinkleTextileRatios (): Promise<WrinkleType[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.None)
      .then(previewImages => this.getWrinkleTextileRatiosFromPreviewImagesCollection(previewImages))
  }

  private getAvailableWrinkleTextileRatiosIds (): Promise<string[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step2 ^ AvailablePreviewImageFilter.WrinkleTextileRatio ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => this.getWrinkleTextileRatiosFromPreviewImagesCollection(previewImages))
      .then(wrinkleTextileRatios => wrinkleTextileRatios.map(wrinkleTextileRatio => wrinkleTextileRatio.id).filter(Boolean) as string[])
  }

  private getWrinkleTextileRatiosFromPreviewImagesCollection (previewImages: PreviewImage[]): WrinkleType[] {
    return previewImages.map(previewImage => (previewImage.wrinkle?.textileRatio)).filter(Boolean)
  }

  private setWrinkleWidthsAsSelectBoxItems (): void {
    Promise.all([
      this.getAllWrinkleWidths(),
      this.getAvailableWrinkleWidths()]
    ).then(([allWrinkleWidths, availableWrinkleWidths]) => {
      this.wrinkleWidthInMillimetersAsSelectBoxItems = allWrinkleWidths.map(wrinkleWidth => {
        return {
          value: new WrinkleWidth(wrinkleWidth),
          title: wrinkleWidth.toString(),
          disabled: !availableWrinkleWidths.includes(wrinkleWidth),
          sorting: wrinkleWidth
        }
      })
    })
  }

  private getAllWrinkleWidths (): Promise<number[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.None)
      .then(previewImages => this.getWrinkleWidthsFromPreviewImagesCollection(previewImages))
  }

  private getAvailableWrinkleWidths (): Promise<number[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step2 ^ AvailablePreviewImageFilter.WrinkleWidth ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => this.getWrinkleWidthsFromPreviewImagesCollection(previewImages))
  }

  private getWrinkleWidthsFromPreviewImagesCollection (previewImages: PreviewImage[]): number[] {
    return previewImages.map(previewImage => (previewImage.wrinkle?.widthInMillimeters)).filter(numberOfFolds => typeof numberOfFolds === 'number')
  }

  private setWrinklePatternRepetitionDistancesAsSelectBoxItems (): void {
    Promise.all([
      this.getAllWrinklePatternRepetitionDistances(),
      this.getAvailableWrinklePatternRepetitionDistances()]
    ).then(([allWrinklePatternRepetitionDistances, availableWrinklePatternRepetitionDistances]) => {
      this.wrinklePatternRepetitionDistanceInMillimetersAsSelectBoxItems = allWrinklePatternRepetitionDistances.map(patternRepetitionDistance => {
        return {
          value: new WrinklePatternRepetitionDistance(patternRepetitionDistance),
          title: patternRepetitionDistance.toString(),
          disabled: !availableWrinklePatternRepetitionDistances.includes(patternRepetitionDistance),
          sorting: patternRepetitionDistance
        }
      })
    })
  }

  private getAllWrinklePatternRepetitionDistances (): Promise<number[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.None)
      .then(previewImages => this.getWrinklePatternRepetitionDistancesFromPreviewImagesCollection(previewImages))
  }

  private getAvailableWrinklePatternRepetitionDistances (): Promise<number[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step2 ^ AvailablePreviewImageFilter.WrinklePatternRepetitionDistance ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => this.getWrinklePatternRepetitionDistancesFromPreviewImagesCollection(previewImages))
  }

  private getWrinklePatternRepetitionDistancesFromPreviewImagesCollection (previewImages: PreviewImage[]): number[] {
    return previewImages.map(previewImage => (previewImage.wrinkle?.patternRepetitionDistanceInMillimeters)).filter(numberOfFolds => typeof numberOfFolds === 'number')
  }
}
