import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import { ConfiguratorStep } from '@/enums/configurator-step'

export class ConfiguratorStepService {
  private readonly configuratorStepsOrder: ConfiguratorStep[] = [
    ConfiguratorStep.Room,
    ConfiguratorStep.Textile,
    ConfiguratorStep.Wrinkle,
    ConfiguratorStep.Size
  ]

  public get firstStep (): ConfiguratorStep {
    return ConfiguratorStep.Room
  }

  public canGoToStep (configuratorSnapshot: ConfiguratorSnapshot, stepToGoTo: ConfiguratorStep): boolean {
    const isRoomSelected = !!configuratorSnapshot.selectedRoom
    const isCurtainSelected = !!configuratorSnapshot.selectedPattern
    const isWrinkleSelected = !!configuratorSnapshot.selectedWrinkle

    switch (stepToGoTo) {
      case ConfiguratorStep.Room:
        return true
      case ConfiguratorStep.Textile:
        return isRoomSelected
      case ConfiguratorStep.Wrinkle:
        return isRoomSelected && isCurtainSelected
      case ConfiguratorStep.Size:
        return isRoomSelected && isCurtainSelected && isWrinkleSelected
    }
  }

  public canSubmit (configuratorSnapshot: ConfiguratorSnapshot): boolean {
    return !!configuratorSnapshot.selectedRoom &&
      !!configuratorSnapshot.selectedPattern &&
      !!configuratorSnapshot.selectedWrinkle &&
      !!configuratorSnapshot.curtainWidthInCentimeters &&
      !!configuratorSnapshot.curtainHeightInCentimeters
  }

  public getStepAfterStep (step: ConfiguratorStep): ConfiguratorStep | null {
    for (let i = 0; i < this.configuratorStepsOrder.length; i++) {
      if (this.configuratorStepsOrder[i] === step) {
        return this.configuratorStepsOrder[i + 1] || null
      }
    }

    return null
  }

  public getStepBeforeStep (step: ConfiguratorStep): ConfiguratorStep | null {
    for (let i = this.configuratorStepsOrder.length - 1; i >= 0; i--) {
      if (this.configuratorStepsOrder[i] === step) {
        return this.configuratorStepsOrder[i - 1] || null
      }
    }

    return null
  }
}
