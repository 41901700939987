import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76a66530"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tiles-image-selector" }
const _hoisted_2 = { class: "tiles-image-selector__inner" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 2,
  class: "tiles-image-selector__item__no-image-info"
}
const _hoisted_5 = {
  key: 0,
  class: "tiles-image-selector__item__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsCollection, (item) => {
        return (_openBlock(), _createElementBlock("button", {
          key: item,
          class: _normalizeClass(["tiles-image-selector__item", { 'tiles-image-selector__item--current': _ctx.isSelectedItem(item) }]),
          onClick: ($event: any) => (_ctx.toggleSelection(item))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["tiles-image-selector__item__image-wrapper", {
                'tiles-image-selector__item__image-wrapper--no-image': !item.imageUrl,
                'tiles-image-selector__item__image-wrapper--has-hover-image': item.hoverImageUrl
             }])
          }, [
            (item.imageUrl)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "tiles-image-selector__item__image tiles-image-selector__item__image--default",
                  style: _normalizeStyle({ backgroundImage: item.imageUrl ? `url(${item.imageUrl})` : 'none' })
                }, null, 4))
              : _createCommentVNode("", true),
            (item.hoverImageUrl)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "tiles-image-selector__item__image tiles-image-selector__item__image--hover",
                  style: _normalizeStyle({ backgroundImage: item.hoverImageUrl ? `url(${item.hoverImageUrl})` : 'none' })
                }, null, 4))
              : _createCommentVNode("", true),
            (!item.imageUrl)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('missingPreviewImage')), 1))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.showTitle)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.title), 1))
            : _createCommentVNode("", true)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}