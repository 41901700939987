
import { BaseComponent } from '@/components/base-component'
import { Options, prop } from 'vue-class-component'
import { Side } from '@/enums/side'
import Label from '@/components/Form/Label.vue'

type SideOption = { value: Side, iconSvgCode: string, title: string };

@Options({
  components: { Label },
  props: {
    label: prop({
      type: String,
      required: false
    }),
    modelValue: prop({
      type: Number,
      required: true,
      validator: (value: unknown): boolean => Object.keys(Side).some(key => (Side as unknown as Record<string, number>)[key] === value)
    })
  },
  watch: {
    selectedItem: function (side: SideOption) {
      this.$emit('update:modelValue', side.value)
    }
  }
})
export default class SideSelector extends BaseComponent {
  public items: SideOption[] = [
    {
      value: Side.Both,
      iconSvgCode: '<svg viewBox="0 0 67 57.137" xmlns="http://www.w3.org/2000/svg"><g><path d="m0 0h67v1h-67z"/><path d="m162.64 145.26c.24.2.533.141.736-.183a2.832 2.832 0 0 1 2.385-1.391 2.488 2.488 0 0 1 .282.038v-46.482a1.137 1.137 0 0 1 2.273 0v48.051a.466.466 0 0 0 .8-.112l.066-.106a2.832 2.832 0 0 1 2.385-1.391 2.669 2.669 0 0 1 .367.05v-46.492a1.137 1.137 0 0 1 2.273 0v48.158c.24.2.533.142.736-.183l.086-.138a2.83 2.83 0 0 1 2.384-1.389 2.527 2.527 0 0 1 .283.038v-46.486a1.137 1.137 0 0 1 2.273 0v48.058a.467.467 0 0 0 .8-.112l.066-.106a2.832 2.832 0 0 1 2.385-1.391 2.482 2.482 0 0 1 .282.016v-51.066h-20.861z" transform="translate(-159.198 -88.372)"/><path d="m162.64 145.26c.24.2.533.141.736-.183a2.832 2.832 0 0 1 2.385-1.391 2.488 2.488 0 0 1 .282.038v-46.482a1.137 1.137 0 0 1 2.273 0v48.051a.466.466 0 0 0 .8-.112l.066-.106a2.832 2.832 0 0 1 2.385-1.391 2.669 2.669 0 0 1 .367.05v-46.492a1.137 1.137 0 0 1 2.273 0v48.158c.24.2.533.142.736-.183l.086-.138a2.83 2.83 0 0 1 2.384-1.389 2.527 2.527 0 0 1 .283.038v-46.486a1.137 1.137 0 0 1 2.273 0v48.058a.467.467 0 0 0 .8-.112l.066-.106a2.832 2.832 0 0 1 2.385-1.391 2.482 2.482 0 0 1 .282.016v-51.066h-20.861z" transform="translate(-120.27 -88.372)"/></g></svg>',
      title: 'curtainSide.both'
    },
    {
      value: Side.Right,
      iconSvgCode: '<svg viewBox="0 0 67 57.1" xmlns="http://www.w3.org/2000/svg"><g><path d="m0 0h67v1h-67z"/><path d="m.5.5h66z"/><path d="m42.2 56.9c.2.2.5.1.7-.2.5-.8 1.4-1.4 2.4-1.4h.3v-46.4c0-.6.5-1.1 1.2-1.1.6 0 1.1.5 1.1 1.1v48.1c.2.2.5.2.7.1.1-.1.1-.1.1-.2l.1-.1c.5-.8 1.4-1.4 2.4-1.4.1 0 .2 0 .4.1v-46.6c0-.6.5-1.1 1.2-1.1.6 0 1.1.5 1.1 1.1v48.1c.2.2.5.1.7-.2l.1-.1c.5-.8 1.4-1.4 2.4-1.4h.3v-46.4c0-.6.5-1.1 1.2-1.1.6 0 1.1.5 1.1 1.1v48.1c.2.2.5.2.7.1.1-.1.1-.1.1-.2l.1-.1c.5-.8 1.4-1.4 2.4-1.4h.3v-51.1h-21.1z"/></g></svg>',
      title: 'curtainSide.right'
    },

    {
      value: Side.Left,
      iconSvgCode: '<svg viewBox="0 0 67 57.1" xmlns="http://www.w3.org/2000/svg"><g><path d="m0 0h67v1h-67z"/><path d="m.5.5h66z"/><path d="m3.1 56.9c.2.2.5.1.7-.2.5-.8 1.4-1.4 2.4-1.4h.3v-46.4c0-.6.5-1.1 1.2-1.1.6 0 1.1.5 1.1 1.1v48.1c.2.2.5.2.7.1.1-.1.1-.1.1-.2l.1-.1c.5-.8 1.4-1.4 2.4-1.4.1 0 .2 0 .4.1v-46.6c0-.6.5-1.1 1.2-1.1.6 0 1.1.5 1.1 1.1v48.1c.2.2.5.1.7-.2l.1-.1c.5-.8 1.4-1.4 2.4-1.4h.3v-46.4c0-.6.5-1.1 1.2-1.1.6 0 1.1.5 1.1 1.1v48.1c.2.2.5.2.7.1.1-.1.1-.1.1-.2l.1-.1c.5-.8 1.4-1.4 2.4-1.4h.3v-51.1h-21.1z"/></g></svg>',
      title: 'curtainSide.left'
    }
  ]

  private readonly modelValue!: Side | null
  private selectedItem: SideOption | null = null

  public created (): void {
    const selectedItem = this.items.find(item => this.isSelectedOption(item)) || null

    if (this.modelValue && !selectedItem) {
      throw new Error(`Could not find side option '${this.modelValue}' which was passed as prop #1662118718059`)
    }

    this.selectedItem = selectedItem
  }

  public isSelectedOption (item: SideOption): boolean {
    return item.value === this.modelValue
  }
}
