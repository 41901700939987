
import { BaseView } from '@/views/base-view'
import { Options } from 'vue-class-component'
import RoomSelection from '@/components/CurtainConfigurator/RoomSelection.vue'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import { ConfiguratorStep } from '@/enums/configurator-step'
import StepNavigator from '@/components/CurtainConfigurator/StepNavigator.vue'
import { Inject } from 'inversify-props'
import StepPaginator from '@/components/CurtainConfigurator/StepPaginator.vue'
import { ConfiguratorStepService } from '@/services/configurator-step.service'
import WrinkleConfiguration from '@/components/CurtainConfigurator/WrinkleConfiguration.vue'
import CurtainPreview from '@/components/CurtainConfigurator/CurtainPreview.vue'
import SnapshotLoader from '@/components/CurtainConfigurator/SnapshotLoader.vue'
import SizeConfiguration from '@/components/CurtainConfigurator/SizeConfiguration.vue'
import { ConfiguratorSnapshotService } from '@/services/configurator-snapshot.service'
import SnapshotCode from '@/components/CurtainConfigurator/SnapshotCode.vue'
import { PrefetchingService } from '@/services/prefetching.service'
import CurtainConfiguration from '@/components/CurtainConfigurator/CurtainConfiguration.vue'
import { AvailablePreviewImageService } from '@/services/available-preview-image.service'
import cloneDeep from 'lodash.clonedeep'

@Options({
  props: {
    step: {
      type: String,
      default: ConfiguratorStep.Room
    }
  },
  components: {
    CurtainConfiguration,
    SizeConfiguration,
    SnapshotLoader,
    WrinkleConfiguration,
    StepPaginator,
    StepNavigator,
    RoomSelection,
    CurtainPreview,
    SnapshotCode
  },
  watch: {
    step: function (step) {
      this.goToPreviousStepIfStepNotAccessible(step)
    }
  }
})
export default class ConfiguratorView extends BaseView {
  @Inject() private configuratorStepService!: ConfiguratorStepService
  @Inject() private configuratorSnapshotService!: ConfiguratorSnapshotService
  @Inject() private prefetchingService!: PrefetchingService
  @Inject() private availablePreviewImageService!: AvailablePreviewImageService
  public readonly step!: ConfiguratorStep
  public readonly steps = ConfiguratorStep
  public configuratorSnapshot: ConfiguratorSnapshot = new ConfiguratorSnapshot()

  public created (): void {
    this.goToPreviousStepIfStepNotAccessible(this.step)
  }

  public mounted (): void {
    this.prefetch()
  }

  public canGoToStep (step: ConfiguratorStep): boolean {
    return this.configuratorStepService.canGoToStep(this.configuratorSnapshot, step)
  }

  public goToPreviousStepIfStepNotAccessible (step: ConfiguratorStep): void {
    if (!this.canGoToStep(step)) {
      this.$router.push({
        name: 'configurator',
        params: { step: this.configuratorStepService.getStepBeforeStep(step) }
      })
    }
  }

  public saveConfiguratorSnapshot (): void {
    this.getConfiguratorSnapshotToPersist().then(configuratorSnapshot => {
      this.configuratorSnapshotService.create(configuratorSnapshot)
        .then(savedConfiguratorSnapshot => {
          this.$notifySuccess(this.$t('saveOrderSuccess'))
          this.configuratorSnapshot = savedConfiguratorSnapshot
        })
        .catch(error => {
          this.$notifySuccess(this.$t('saveOrderError'))
          console.error(error)
        })
    })
  }

  public loadSnapshot (configuratorSnapshot: ConfiguratorSnapshot) {
    // reset code because we don't want to work on existing snapshot (e.g. when saving it again we don't want to update
    // existing snapshot - we want to create a new one)
    configuratorSnapshot.code = ''
    this.configuratorSnapshot = configuratorSnapshot
    this.$router.push({
      name: 'configurator',
      params: { step: this.configuratorStepService.firstStep }
    })
  }

  private getConfiguratorSnapshotToPersist (): Promise<ConfiguratorSnapshot> {
    return this.availablePreviewImageService.fetchByConfigurationSnapshot(this.configuratorSnapshot).then(
      previewImages => {
        const configuratorSnapshot = cloneDeep(this.configuratorSnapshot)

        if (previewImages[0]?.curtain) {
          configuratorSnapshot.selectedCurtain = previewImages[0].curtain
        }

        return configuratorSnapshot
      }
    )
  }

  // @todo add more logic here instead of being so eager:
  //   - Some things should be fetched when user is just step before
  //   - Rooms details could be fetched when room is visible in carousel etc.
  private prefetch (): void {
    setTimeout(() => this.prefetchingService.prefetchAll(), 1000)
  }
}
