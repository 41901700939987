import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb932980"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-6 col-lg-4" }
const _hoisted_3 = { class: "col-6 col-lg-4" }
const _hoisted_4 = { class: "col-6 col-lg-4" }
const _hoisted_5 = { class: "col-6 col-lg-4" }
const _hoisted_6 = { class: "col-6 col-lg-4" }
const _hoisted_7 = { class: "col-6 col-lg-4" }
const _hoisted_8 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectBox = _resolveComponent("SelectBox")!
  const _component_TilesImageSelector = _resolveComponent("TilesImageSelector")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SelectBox, {
          items: _ctx.wrinkleTypesAsSelectBoxItems,
          label: _ctx.$t('wrinkleTypesSelectFieldLabel'),
          placeholder: _ctx.$t('wrinkleTypesNoSelection'),
          modelValue: _ctx.configuratorSnapshot.selectedWrinkleType,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configuratorSnapshot.selectedWrinkleType) = $event))
        }, null, 8, ["items", "label", "placeholder", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SelectBox, {
          items: _ctx.wrinkleNumberOfFoldsAsSelectBoxItems,
          label: _ctx.$t('wrinkleNumberOfFoldsSelectFieldLabel'),
          placeholder: _ctx.$t('wrinkleNumberOfFoldsNoSelection'),
          modelValue: _ctx.configuratorSnapshot.selectedWrinkleNumberOfFolds,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.configuratorSnapshot.selectedWrinkleNumberOfFolds) = $event))
        }, null, 8, ["items", "label", "placeholder", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SelectBox, {
          items: _ctx.wrinkleColorsAsSelectBoxItems,
          label: _ctx.$t('wrinkleColorSelectFieldLabel'),
          placeholder: _ctx.$t('wrinkleColorNoSelection'),
          modelValue: _ctx.configuratorSnapshot.selectedWrinkleColor,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.configuratorSnapshot.selectedWrinkleColor) = $event))
        }, null, 8, ["items", "label", "placeholder", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SelectBox, {
          items: _ctx.wrinkleTextileRatiosAsSelectBoxItems,
          label: _ctx.$t('wrinkleTextileRatioSelectFieldLabel'),
          placeholder: _ctx.$t('wrinkleTextileRatioNoSelection'),
          modelValue: _ctx.configuratorSnapshot.selectedWrinkleTextileRatio,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.configuratorSnapshot.selectedWrinkleTextileRatio) = $event))
        }, null, 8, ["items", "label", "placeholder", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_SelectBox, {
          items: _ctx.wrinkleWidthInMillimetersAsSelectBoxItems,
          label: _ctx.$t('wrinkleWidthSelectFieldLabel'),
          placeholder: _ctx.$t('wrinkleWidthNoSelection'),
          modelValue: _ctx.configuratorSnapshot.selectedWrinkleWidthInMillimeters,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.configuratorSnapshot.selectedWrinkleWidthInMillimeters) = $event))
        }, null, 8, ["items", "label", "placeholder", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_SelectBox, {
          items: _ctx.wrinklePatternRepetitionDistanceInMillimetersAsSelectBoxItems,
          label: _ctx.$t('wrinklePatternRepetitionDistanceSelectFieldLabel'),
          placeholder: _ctx.$t('wrinklePatternRepetitionDistanceNoSelection'),
          modelValue: _ctx.configuratorSnapshot.selectedWrinklePatternRepetitionDistanceInMillimeters,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.configuratorSnapshot.selectedWrinklePatternRepetitionDistanceInMillimeters) = $event))
        }, null, 8, ["items", "label", "placeholder", "modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.wrinklesAsTilesImageItems)
        ? (_openBlock(), _createBlock(_component_TilesImageSelector, {
            key: 0,
            items: _ctx.wrinklesAsTilesImageItems,
            modelValue: _ctx.configuratorSnapshot.selectedWrinkle,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.configuratorSnapshot.selectedWrinkle) = $event)),
            "preselect-first": false
          }, null, 8, ["items", "modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}