
import { BaseComponent } from '@/components/base-component'
import { Options, prop } from 'vue-class-component'
import { PropType } from 'vue'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import { Inject } from 'inversify-props'
import { RoomService } from '@/services/room.service'
import { PreviewImage } from '@/models/preview-image'
import { AvailablePreviewImageService } from '@/services/available-preview-image.service'
import PrimaryButton from '@/components/Ui/Button/PrimaryButton.vue'
import { AvailablePreviewImageFilter } from '@/enums/available-preview-image-filter'

@Options({
  components: {
    PrimaryButton
  },
  props: {
    configuratorSnapshot: prop({
      type: Object as PropType<ConfiguratorSnapshot>,
      required: true,
      validator: (value: unknown): boolean => value instanceof ConfiguratorSnapshot
    })
  },
  watch: {
    'configuratorSnapshot.selectedRoom' () {
      this.loadPreviewImage()
    },
    'configuratorSnapshot.selectedTransparency' () {
      this.loadPreviewImage()
    },
    'configuratorSnapshot.selectedPattern' () {
      this.loadPreviewImage()
    },
    'configuratorSnapshot.selectedWrinkle' () {
      this.loadPreviewImage()
    },
    'configuratorSnapshot.curtainSide' () {
      this.loadPreviewImage()
    }
  }
})
export default class CurtainPreview extends BaseComponent {
  @Inject() private readonly roomService!: RoomService
  @Inject() private readonly availablePreviewImageService!: AvailablePreviewImageService
  public readonly configuratorSnapshot!: ConfiguratorSnapshot
  public previewImage: PreviewImage | null = null
  public error: Error | null = null

  public get htmlWrapperElement (): HTMLElement {
    if (!this.$refs.wrapperElement) {
      console.warn('Could not determine wrapper element of CurtainPreview component #1672594816564')
    }

    return this.$refs.wrapperElement as HTMLElement
  }

  public created (): void {
    this.loadPreviewImage()
  }

  public mounted (): void {
    this.setImageTopOffset()
    window.addEventListener('resize', () => this.setImageTopOffset())
  }

  public restartConfigurator (): void {
    const appRoute = this.$router.resolve({ name: 'configurator' })
    window.location.href = new URL(appRoute.href, window.location.origin).href
  }

  private determinePreviewImage (): Promise<PreviewImage | null> {
    const selectedRoom = this.configuratorSnapshot.selectedRoom
    if (!selectedRoom) {
      return Promise.resolve(null)
    }

    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.AffectingPreviewImage)
      .then(availableCurtains => availableCurtains[0] || null)
  }

  private loadPreviewImage (): void {
    try {
      this.determinePreviewImage()
        .then(previewImage => {
          this.error = null
          this.previewImage = previewImage
        })
        .catch(error => {
          throw error
        })
    } catch (error: unknown) {
      this.error = error as Error
    }
  }

  private setImageTopOffset (): void {
    (this.htmlWrapperElement as HTMLElement).style.setProperty('--curtain-preview-image-top-offset', this.htmlWrapperElement.getBoundingClientRect().top + 'px')
  }
}
