import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-278860bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }),
    (_ctx.itemsCollection)
      ? (_openBlock(), _createBlock(_component_Multiselect, {
          key: 0,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          options: _ctx.itemsCollection,
          "value-prop": _ctx.valuePropertyName,
          label: 'title',
          placeholder: _ctx.placeholder,
          "can-clear": _ctx.clearable,
          mode: _ctx.mode,
          hideSelected: false,
          closeOnSelect: _ctx.isCloseOnSelect
        }, null, 8, ["modelValue", "options", "value-prop", "placeholder", "can-clear", "mode", "closeOnSelect"]))
      : _createCommentVNode("", true)
  ]))
}