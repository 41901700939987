import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2388bf63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-selector" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("button", {
        key: item.value,
        class: _normalizeClass(["side-selector__button", { 'side-selector__button--active': _ctx.isSelectedOption(item) }]),
        onClick: ($event: any) => (_ctx.selectedItem = item)
      }, [
        _createElementVNode("span", {
          innerHTML: item.iconSvgCode
        }, null, 8, _hoisted_3),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t(item.title)), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}