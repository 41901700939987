
import { Options } from 'vue-class-component'
import { BaseStepComponent, baseStepComponentProps } from '@/components/CurtainConfigurator/base-step-component'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import { SelectBoxItem } from '@/interfaces/select-box-item'
import { Transparency } from '@/models/transparency'
import { Inject } from 'inversify-props'
import { AvailablePreviewImageService } from '@/services/available-preview-image.service'
import { AvailablePreviewImageFilter } from '@/enums/available-preview-image-filter'
import SelectBox from '@/components/Form/Input/SelectBox.vue'
import { TextileFeature } from '@/models/textile-feature'
import { TilesImageItem } from '@/interfaces/tiles-image-item'
import { Pattern } from '@/models/pattern'
import TilesImageSelector from '@/components/Form/Input/TilesImageSelector.vue'
import { PreviewImage } from '@/models/preview-image'

type TextileFeatureSelectBoxItem = SelectBoxItem<TextileFeature>
type TransparencySelectBoxItem = SelectBoxItem<Transparency>
type PatternTilesImageItem = TilesImageItem<Pattern>

@Options({
  components: {
    SelectBox,
    TilesImageSelector
  },
  props: baseStepComponentProps,
  watch: {
    'configuratorSnapshot.selectedTextileFeatures' () {
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedTransparency' () {
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedPattern' () {
      this.setFiltersValues()
    }
  }
})
export default class CurtainConfiguration extends BaseStepComponent {
  @Inject() private readonly availablePreviewImageService!: AvailablePreviewImageService
  public readonly configuratorSnapshot!: ConfiguratorSnapshot
  public textileFeaturesAsSelectBoxItems: TextileFeatureSelectBoxItem[] | null = null
  public transparenciesAsSelectBoxItems: TransparencySelectBoxItem[] | null = null
  public patternsAsTilesImageItems: PatternTilesImageItem[] | null = null

  public created (): void {
    this.setFiltersValues()
  }

  private setFiltersValues (): void {
    this.setTextileFeaturesAsSelectBoxItems()
    this.setTransparenciesAsSelectBoxItems()
    this.setPatternsAsTilesImageItems()
  }

  private setTextileFeaturesAsSelectBoxItems (): void {
    Promise.all([
      this.getAllTextileFeatures(),
      this.getAvailableTextileFeaturesIds()]
    ).then(([allTextileFeatures, availableTextileFeaturesIds]) => {
      this.textileFeaturesAsSelectBoxItems = allTextileFeatures.map(textileFeature => {
        return {
          value: textileFeature,
          title: textileFeature.title,
          disabled: Boolean(textileFeature.id && !availableTextileFeaturesIds.includes(textileFeature.id)),
          sorting: textileFeature.sorting
        }
      })
    })
  }

  private getAllTextileFeatures (): Promise<TextileFeature[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.None)
      .then(previewImages => this.getTextileFeaturesFromPreviewImagesCollection(previewImages))
  }

  private getAvailableTextileFeaturesIds (): Promise<string[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step1 ^ AvailablePreviewImageFilter.TextileFeatures)
      .then(previewImages => this.getTextileFeaturesFromPreviewImagesCollection(previewImages))
      .then(textileFeatures => textileFeatures.map(textileFeature => textileFeature.id).filter(Boolean) as string[])
  }

  private getTextileFeaturesFromPreviewImagesCollection (previewImages: PreviewImage[]): TextileFeature[] {
    return [].concat(...previewImages.map(previewImage => (previewImage.curtain?.textileFeatures as never[]) || []))
  }

  private setTransparenciesAsSelectBoxItems (): void {
    Promise.all([
      this.getAllTransparencies(),
      this.getAvailableTransparenciesIds()]
    ).then(([allTransparencies, availableTransparenciesIds]) => {
      this.transparenciesAsSelectBoxItems = allTransparencies.map(transparency => {
        return {
          value: transparency,
          title: transparency.title,
          disabled: Boolean(transparency.id && !availableTransparenciesIds.includes(transparency.id)),
          sorting: transparency.sorting
        }
      })
    })
  }

  private getAllTransparencies (): Promise<Transparency[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.None)
      .then(previewImages => this.getTransparenciesFromPreviewImagesCollection(previewImages))
  }

  private getAvailableTransparenciesIds (): Promise<string[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step1 ^ AvailablePreviewImageFilter.Transparency)
      .then(previewImages => this.getTransparenciesFromPreviewImagesCollection(previewImages))
      .then(transparencies => transparencies.map(transparency => transparency.id).filter(Boolean) as string[])
  }

  private getTransparenciesFromPreviewImagesCollection (previewImages: PreviewImage[]): Transparency[] {
    return previewImages.map(previewImage => (previewImage.curtain?.transparency)).filter(Boolean)
  }

  private setPatternsAsTilesImageItems (): void {
    this.getAvailableDistinctPatterns()
      .then(patterns => patterns.sort((patternA, patternB) => patternA.sorting - patternB.sorting))
      .then(patterns => {
        this.patternsAsTilesImageItems = patterns.map(pattern => ({
          value: pattern,
          title: pattern.title,
          imageUrl: pattern.previewImage?.url
        }))
      })
  }

  private getAvailableDistinctPatterns (): Promise<Pattern[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step1 ^ AvailablePreviewImageFilter.Pattern)
      .then(previewImages => {
        const patterns = previewImages
          .filter(previewImage => !!previewImage.curtain?.pattern)
          .map(previewImage => previewImage.curtain.pattern)

        return Array.from(new Map(patterns.map(pattern => [pattern.uid, pattern])).values())
      })
  }
}
