
import { BaseComponent } from '@/components/base-component'
import { Options, prop } from 'vue-class-component'
import { SelectBoxItem, SelectBoxItemValue } from '@/interfaces/select-box-item'
import Multiselect from '@vueform/multiselect'
import { PropType } from 'vue'
import Label from '@/components/Form/Label.vue'

type InputModelValueType = SelectBoxItemValue | SelectBoxItemValue[] | null
type SingleModelValueType = string | null
type MultiModelValueType = string[] | null
type ModelValueType = SingleModelValueType | MultiModelValueType
type PropItems = Promise<SelectBoxItem[]> | SelectBoxItem[];
type SelectBoxOption = SelectBoxItem & {
  valueUniqueIdentifier: string
}

enum Mode {
  Single = 'single',
  Tags = 'tags',
}

@Options({
  components: {
    Label,
    Multiselect
  },
  props: {
    label: prop({
      type: String,
      required: false
    }),
    items: prop({
      type: Object as PropType<PropItems>,
      required: true,
      validator: (value: unknown): boolean => Array.isArray(value) || value instanceof Promise
    }),
    modelValue: prop({
      type: Object as PropType<SelectBoxItemValue>,
      required: false
    }),
    isMultiselect: prop({
      type: Boolean,
      default: false
    }),
    placeholder: prop({
      type: String,
      required: false
    }),
    preselectFirst: prop({
      type: Boolean,
      default: false
    }),
    clearable: prop({
      type: Boolean,
      default: true
    }),
    makeDistinct: prop({
      type: Boolean,
      default: true
    }),
    sortItemsBySortingField: prop({
      type: Boolean,
      default: true
    })
  },
  watch: {
    items: function () {
      this.initializeItems()
    }
  }
})
export default class SelectBox extends BaseComponent {
  public readonly items!: PropItems
  public itemsCollection: SelectBoxOption[] | null = null
  public readonly valuePropertyName: string = 'valueUniqueIdentifier'
  private readonly modelValue!: InputModelValueType
  private readonly preselectFirst!: boolean
  private readonly isMultiselect!: boolean
  private readonly makeDistinct!: boolean
  private readonly sortItemsBySortingField!: boolean

  public get value (): ModelValueType {
    if (this.isMultiselect) {
      return ((this.modelValue || []) as SelectBoxItemValue[]).map(modelValue => this.itemValueToValueUniqueIdentifier(modelValue))
    }

    return this.modelValue ? this.itemValueToValueUniqueIdentifier(this.modelValue as SelectBoxItemValue) : null
  }

  public set value (value: ModelValueType) {
    if (this.isSameValue(this.value, value)) {
      return
    }
    this.$emit('update:modelValue', this.getInputValueFromModelValue(value))
  }

  public get mode (): Mode {
    return this.isMultiselect ? Mode.Tags : Mode.Single
  }

  public get isCloseOnSelect (): boolean {
    return !this.isMultiselect
  }

  public created (): void {
    this.initializeItems()
  }

  private preselectFirstItem (): void {
    if (!this.value && this.preselectFirst && this.itemsCollection && this.itemsCollection[0]) {
      this.value = this.itemValueToValueUniqueIdentifier(this.itemsCollection[0].value)
    }
  }

  private initializeItems (): void {
    this.itemsAsPromise()
      .then(items => this.getDistinctItemsFromItems(items))
      .then(items => this.getSortedItemsFromItems(items))
      .then(items => {
        this.itemsCollection = items.map(item => ({
          ...item,
          valueUniqueIdentifier: this.itemValueToValueUniqueIdentifier(item.value)
        }))
      })
      .then(() => this.preselectFirstItem())
  }

  private itemValueToValueUniqueIdentifier (item: SelectBoxItemValue): string {
    return item.uniqueIdentifier
  }

  private getInputValueFromModelValue (modelValue: ModelValueType): InputModelValueType {
    if (this.isMultiselect) {
      if (Array.isArray(modelValue)) {
        return modelValue.map(value => this.getInputValueFromSingleModelValue(value) as SelectBoxItemValue)
      }

      return []
    }

    return this.getInputValueFromSingleModelValue(modelValue as SingleModelValueType)
  }

  private getInputValueFromSingleModelValue (modelValue: SingleModelValueType): InputModelValueType {
    return (this.itemsCollection?.find(item => this.isSameValue(item.valueUniqueIdentifier, modelValue))?.value) || null
  }

  private isSameValue (valueA: ModelValueType, valueB: ModelValueType): boolean {
    if (valueA === null || valueB === null) {
      return valueA === valueB
    }

    if (Array.isArray(valueA) && Array.isArray(valueB)) {
      return valueA.every(value => valueB.includes(value)) &&
        valueB.every(value => valueA.includes(value))
    } else if (Array.isArray(valueA) || Array.isArray(valueB)) {
      return false
    }

    return valueA === valueB
  }

  private itemsAsPromise (): Promise<SelectBoxItem[]> {
    if (this.items instanceof Promise) {
      return this.items
    }

    return Promise.resolve(this.items)
  }

  private getDistinctItemsFromItems (items: SelectBoxItem[]): SelectBoxItem[] {
    if (!this.makeDistinct) {
      return items
    }

    const distinctItems: Map<string, SelectBoxItem> = new Map()
    items.forEach(item => distinctItems.set(item.value.uniqueIdentifier, item))

    return Array.from(distinctItems.values())
  }

  private getSortedItemsFromItems (items: SelectBoxItem[]): SelectBoxItem[] {
    if (!this.sortItemsBySortingField) {
      return items
    }

    return items.sort((itemA, itemB) => {
      if (typeof itemA.sorting === 'number' && typeof itemB.sorting !== 'number') {
        return 1
      }

      if (typeof itemA.sorting !== 'number' && typeof itemB.sorting === 'number') {
        return -1
      }

      if (typeof itemA.sorting !== 'number' && typeof itemB.sorting !== 'number') {
        return 0
      }

      return Number(itemA.sorting) - Number(itemB.sorting)
    })
  }
}
