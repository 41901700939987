import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-068e3223"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "col-sm-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectBox = _resolveComponent("SelectBox")!
  const _component_TilesImageSelector = _resolveComponent("TilesImageSelector")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.textileFeaturesAsSelectBoxItems)
          ? (_openBlock(), _createBlock(_component_SelectBox, {
              key: 0,
              items: _ctx.textileFeaturesAsSelectBoxItems,
              label: _ctx.$t('textileFeatureSelectFieldLabel'),
              placeholder: _ctx.$t('textileFeatureNoSelection'),
              "is-multiselect": true,
              modelValue: _ctx.configuratorSnapshot.selectedTextileFeatures,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configuratorSnapshot.selectedTextileFeatures) = $event))
            }, null, 8, ["items", "label", "placeholder", "modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.transparenciesAsSelectBoxItems)
          ? (_openBlock(), _createBlock(_component_SelectBox, {
              key: 0,
              items: _ctx.transparenciesAsSelectBoxItems,
              label: _ctx.$t('textileSelectFieldLabel'),
              placeholder: _ctx.$t('textileNoSelection'),
              "preselect-first": true,
              clearable: false,
              modelValue: _ctx.configuratorSnapshot.selectedTransparency,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.configuratorSnapshot.selectedTransparency) = $event))
            }, null, 8, ["items", "label", "placeholder", "modelValue"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.patternsAsTilesImageItems)
      ? (_openBlock(), _createBlock(_component_TilesImageSelector, {
          key: 0,
          items: _ctx.patternsAsTilesImageItems,
          modelValue: _ctx.configuratorSnapshot.selectedPattern,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.configuratorSnapshot.selectedPattern) = $event)),
          "preselect-first": false,
          "show-title": false
        }, null, 8, ["items", "modelValue"]))
      : _createCommentVNode("", true)
  ]))
}