export default {
  "error": {
    "couldNotInitializeAppDueMissingConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung konnte nicht initialisiert werden (fehlende Konfiguration)"])}
  },
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurator"])},
  "introMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem Vorhang-Konfigurator zum Traumvorhang. Stelle dir deinen individuellen Vorhang direk am Computer zusammen."])},
  "loadOrder": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie bereits eine Konfiguration und möchten bestellen?"])},
    "introButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt bestellung fortsetzen"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung fortsetzen"])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gaben Sie hier Ihren ", _interpolate(_named("codeLength")), "-Zeichen Code ein und setzen Sie die Bestellung fort."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten. Sind Sie sicher, dass es eine solche Bestellung gibt?"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung erfolgreich geladen"])},
    "submitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsetzen"])}
  },
  "copyOrderCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später fertigstellen? Kein Problem - Code abspeichern und später Bestellung tätigen:"])},
  "saveOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Speichern der Bestellung ist ein Fehler aufgetreten. Bitte versuche es erneut."])},
  "saveOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung erfolgreich gespeichert"])},
  "roomStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Räume"])},
  "roomSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für welchen Raum benötigen Sie einen Vorhang?"])},
  "textileStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoff"])},
  "textileSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparenz"])},
  "textileNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "textileFeatureSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenschaften"])},
  "textileFeatureNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "wrinkleStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faltenart"])},
  "wrinklesFrequencyNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine"])},
  "wrinkleSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Art von Falten wollen sie?"])},
  "wrinkleDistanceFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie groß soll der Abstand zwischen den Falten sien?"])},
  "wrinkleTypesSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faltenart"])},
  "wrinkleTypesNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "wrinkleNumberOfFoldsSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faltenanzahl"])},
  "wrinkleNumberOfFoldsNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "wrinkleColorSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
  "wrinkleColorNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "wrinkleTextileRatioSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoffverhältnis"])},
  "wrinkleTextileRatioNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "wrinkleWidthSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite (mm)"])},
  "wrinkleWidthNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "wrinklePatternRepetitionDistanceSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport (mm)"])},
  "wrinklePatternRepetitionDistanceNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "sizeStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maße"])},
  "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
  "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe"])},
  "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cm"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiert"])},
  "missingPreviewImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Bild"])},
  "millimetersSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mm"])},
  "previewErrorStartOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten. Beginnen Sie bitte von vorne."])},
  "curtainSide": {
    "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beidseitig"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechts"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])}
  }
}